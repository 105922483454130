.page-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  margin-top: 24px;
}

.page-footer button {
  width: 100px;
}

.page-footer button+button {
  margin-left: 24px;
}