.page-header-content {
    height: 55px;
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    padding: 0 20px;
}

.page-header-content .title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
}

.page-header-content .ext-content {
    margin-left: auto;
}

.page-header-content .ext-btn {
    width: 170px;
}