.page-content {
  background-color: white;
  border: 1px solid #ccc;
  padding: 15px 25px 20px;
  margin-bottom: 20px;
}

.page-content .content-title {
  font-size: 20px;
  line-height: 1.5;
  font-weight: bold;
  color: #505050;
  margin-bottom: 20px;
}

.grid-content {
  display: grid;
}

.grid-content .grid-item {
  display: flex;
  border: 1px solid #c6c6c6;
  min-height: 46px;
}

.grid-content .grid-item .label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  background-color: rgba(42, 130, 228, 0.0857);
  border-right: 1px solid #d8d8d8;
}

.grid-content .grid-item .input-content,
.grid-content .grid-item .value {
  flex: 1;
}

.grid-content .grid-item .value {
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.grid-content .grid-item .input-content .ant-select-selector,
.grid-content .grid-item .input-content .ant-input-number-input-wrap,
.grid-content .grid-item .input-content .ant-input-number-input,
.grid-content .grid-item .input-content .ant-select-selection-search-input {
  height: 100%;
}

.grid-content .grid-item .input-content .ant-select-selection-placeholder,
.grid-content .grid-item .input-content .ant-select-selection-item {
  line-height: 42px;
}

.grid-content.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-3 .grid-item:nth-child(3n + 2),
.grid-3 .grid-item:nth-child(3n + 3) {
  border-left-width: 0px;
}

.grid-3 .grid-item:nth-child(n + 4) {
  border-top-width: 0px;
}

.grid-content.grid-5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid-5 .grid-item:nth-child(5n + 2),
.grid-5 .grid-item:nth-child(5n + 3),
.grid-5 .grid-item:nth-child(5n + 4),
.grid-5 .grid-item:nth-child(5n + 5) {
  border-left-width: 0px;
}

.flex-1 {
  flex: 1;
}

.ml-10 {
  margin-left: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.ant-table tr td {
  word-break: break-all;
}