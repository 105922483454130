.main-content {
    display: flex;
    height: 100vh;
}

.main-content .login-content {
    display: flex;
    flex-direction: column;
    padding: 191px 72px 0 109px;
    width: 445px;
    box-sizing: content-box;
}

.main-content .login-content .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 34px;
    font-weight: bold;
    line-height: 1.5;
}

.main-content .login-content .border {
    border-top: 1px solid #e5e5e5;
    margin: 23px 0 42px;
}

.main-content .login-content .input-prefix {
    width: 100px;
    padding: 0 15px 0;
}

.main-content .login-content .note {
    margin-top: 20px;
    text-align: center;
}

/* .main-content .login-content :global(.ant-input-prefix) {
    justify-content: center;
} */

.main-content .left-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #006B9F;
}

.main-content .left-content .header-content {
    padding: 25px 0 25px 80px;
}

.main-content .left-content .header-content img {
    height: 72px;
}

.main-content .left-content .img-content {
    flex: 1;
    background-image: url(images/login-background.png);
    background-size: auto 100%;
    /* background-position-x: 30%; */
}