.rule-line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.rule-value-input {
    width: 80px;
}

.action-rule {
    display: flex;
}