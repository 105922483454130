.logo-content {
  display: flex;
  align-items: center;
  height: 68px;
  padding: 0 32px;
  background-color: #022b5a;
}

.logo-content img {
  width: 100%;
}

.border {
  border-bottom: 2px solid #ffffff;
  opacity: 0.5;
}

.account-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: #022b5a;
  color: #ffffff;
  padding: 0 15px 0 28px;
}

.account-info .logout-btn {
  padding: 0;
  font-size: 17px;
  color: #ffffff;
}

.info-content {
  flex: 1;
  width: 0;
}

.account-name {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  margin-right: 10px;
}