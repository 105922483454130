.pagination-content {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.total-count {
    font-size: 16px;
    line-height: 1.5;
    color: #505050;
    margin-bottom: 15px;
}