.layout-content {
  display: flex;
  flex-direction: column;
}

.main-layout-content {
  flex: 1;
  overflow-y: auto;
}

.page-content {
  padding: 20px;
  overflow-y: auto;
}