.filter-bar {
    display: flex;
    border: 1px solid #cccccc;
    background-color: white;
    margin-bottom: 15px;
    padding: 10px 20px;
}

.filter-bar .search-items-wrap {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
}

.filter-bar .search-item {
    display: flex;
    align-items: center;
}

.filter-bar .search-item .label {
    white-space: nowrap;
    font-size: 14px;
    line-height: 1.5;
    color: #505050;
}

.filter-bar .search-item .input {
    width: 120px;
}

.filter-bar .search-item .date-range {
    width: 230px;
}

.filter-bar .search-btns {
    display: flex;
}

.filter-bar .search-btns .search-btn {
    width: 80px;
    margin-left: 10px;
}

.filter-bar .order-service-time {
    width: 140px;
    margin-left: 13px;
}