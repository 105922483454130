.main-header-bar {
  display: flex;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #5c5f92;
  padding-left: 15px;
}

.main-header-bar .date {
  margin-left: 12px;
}
